import { useQuery } from 'react-query'
import { apiAuthClient } from '@app/services/apiAuthClient'
import { endpoints } from '@app/api/config'
import {
  ApiErrorResponse,
  ApiUserModules,
  ApiUserModulesResponse,
} from 'BackendApi'
import { getRandomExampleHeader } from '@app/api/utils/mock'

const fetchUserModules = async (): Promise<ApiUserModules | undefined> => {
  const apiResponse = await apiAuthClient.get<ApiUserModulesResponse>(
    endpoints.getUserModules,
    {
      headers: {
        ...getRandomExampleHeader([
          // 'email-receipt-enabled',
          // 'email-receipt-disabled',
          // 'filters-enabled',
          // 'all-disabled',
            'default'
        ]),
      },
    }
  )
  return apiResponse.data?.data
}

export const useGetUserModules = (shouldExecute?: boolean) =>
  useQuery<ApiUserModules | undefined, ApiErrorResponse>(
    ['userModules'],
    fetchUserModules,
    {
      staleTime: 5000,
        enabled: shouldExecute
    }
  )
